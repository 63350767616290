import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        asunto: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_zbl5e9g', // replace with your EmailJS service ID
            'template_aaij598',
            formData,
            '2qnl275lOMpBYelkp' // replace with your EmailJS public key
        ).then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

        setFormData({
            name: '',
            email: '',
            phone: '',
            asunto: '',
            message: ''
        });
    };

    return (
        <section className="contact">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">Contacto</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-envelope"></i>
                                        <h5>Mail</h5>
                                        <h6>parisnegociosinmobiliarios@gmail.com</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-map-marker-alt"></i>
                                        <h5>Ubicacion</h5>
                                        <h6>calle 28 n°917, Navarro, Buenos Aires</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-phone-alt"></i>
                                        <h5>Telefono</h5>
                                        <h6>2227-535057</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <label>Nombre y Apellido</label>
                                    <input 
                                        type="text" 
                                        name="name"
                                        className="inp-contact" 
                                        value={formData.name} 
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <label>Email</label>
                                    <input 
                                        type="email" 
                                        name="email"
                                        className="inp-contact" 
                                        value={formData.email} 
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <label>Telefono</label>
                                    <input 
                                        type="text" 
                                        name="phone"
                                        className="inp-contact" 
                                        value={formData.phone} 
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <label>Asunto</label>
                                    <input 
                                        type="text" 
                                        name="asunto"
                                        className="inp-contact" 
                                        value={formData.asunto} 
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <label>Mensaje</label>
                                    <textarea 
                                        name="message"
                                        className="ta-contact" 
                                        rows="4"
                                        value={formData.message}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                                <div className="col-lg-12">
                                    <button className="btn-contact" onClick={handleSubmit}>Enviar</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;
