import { useState } from 'react';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emailjs from 'emailjs-com';

const Sidebar = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        asunto: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_zbl5e9g', // replace with your EmailJS service ID
            'template_aaij598',
            formData,
            '2qnl275lOMpBYelkp' // replace with your EmailJS public key
        ).then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

        setFormData({
            name: '',
            phone: '',
            asunto: '',
            message: ''
        });
    };

    return (
        <div >
            <div className="right-sidebar">
                <div className="widget-contact">
                    <section className="contact" style={{ width: '100%' }}>
                        <div className="page-content" style={{ paddingBottom: 20, paddingTop: 20 }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12" >
                                        <h2 style={{ textAlign: 'center' }}>Contacto</h2>
                                        <div className="row mt-5">
                                            <div className="col-lg-12">
                                                <label>Nombre y Apellido</label>
                                                <input 
                                                    type="text" 
                                                    name="name"
                                                    className="inp-contact" 
                                                    style={{ width: '100%' }} 
                                                    value={formData.name} 
                                                    onChange={handleChange} 
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Teléfono</label>
                                                <input 
                                                    type="text" 
                                                    name="phone"
                                                    className="inp-contact" 
                                                    style={{ width: '100%' }} 
                                                    value={formData.phone} 
                                                    onChange={handleChange} 
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Asunto</label>
                                                <input 
                                                    type="text" 
                                                    name="asunto"
                                                    className="inp-contact" 
                                                    style={{ width: '100%' }} 
                                                    value={formData.asunto} 
                                                    onChange={handleChange} 
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Mensaje</label>
                                                <textarea 
                                                    name="message"
                                                    className="ta-contact" 
                                                    rows="4" 
                                                    style={{ width: '100%' }}
                                                    value={formData.message} 
                                                    onChange={handleChange}
                                                ></textarea>
                                            </div>
                                            <div className="col-lg-12">
                                                <button 
                                                    className="btn-contact" 
                                                    style={{ width: '100%' }} 
                                                    onClick={handleSubmit}
                                                >
                                                    Enviar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '40px' }}>
                    <a href="https://www.instagram.com/parisnegociosinmobiliarios" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div className="icon IgIcon" style={{ maxWidth: '30px', maxHeight: '30px' }}>
                            <i className="fab fa-instagram"></i>
                        </div>
                    </a>
                    <a href="mailto:tuemail@dominio.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div className="icon" style={{ maxWidth: '30px', maxHeight: '30px' }}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                    </a>
                    <a href="tel:+5492227535057" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div className="icon" style={{ maxWidth: '30px', maxHeight: '30px' }}>
                            <FontAwesomeIcon icon={faPhone} />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
