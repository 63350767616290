import React from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

const Header = () => {
    // Estilos para el menú de hamburguesa
    const styles = {
        bmBurgerButton: {
            position: 'absolute',
            width: '36px',
            height: '30px',
            right: '36px',
            top: '36px'
        },
        bmBurgerBars: {
            background: 'white'
        },
        bmBurgerBarsHover: {
            background: '#a90000'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            background: '#012161',
            padding: '3em 1.5em 0',
            fontSize: '1em'
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: '#b8b7ad',
            padding: '0.8em'
        },
        bmItem: {
            textAlign:"center",
            display: 'block',
            padding: '0.7em 0.1em',
            color: 'white',
            textDecoration: 'none',
            transition: 'background 0.2s ease-in-out',
            fontSize: '22px'
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    };

    return (
        <div className="header">
            <div className="mobile-menu">
                <Menu styles={styles} right>
                    <Link className="menu-item" to="/" style={styles.bmItem}>Inicio</Link>
                    <Link className="menu-item" to="/blog" style={styles.bmItem}>Nuestras Propiedades</Link>
                    <Link className="menu-item" to="/about" style={styles.bmItem}>Sobre Nosotros</Link>
                    <Link className="menu-item" to="/contact" style={styles.bmItem}>Contactos</Link>
                </Menu>
            </div>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <Link to="/">
                            <div style={{ padding: 8 }}>
                                <img src={"/img/LOGOINMO.png"} style={{ maxHeight: 70 }} alt="Logo" />
                            </div>
                        </Link>
                        <div className="desktop-menu collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Inicio</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/blog">Nuestras Propiedades</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about">Sobre Nosotros</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact">Contactos</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Header;
